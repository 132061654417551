import addEditModal from "../IntentionDetail/components/addEditSaleInfo.vue";
import fllowUprecord from "../IntentionDetail/components/followUprecord";
import makePlan from "../IntentionDetail/components/makePlan.vue";
import FillinfollowUp from '../IntentionDetail/components/FillinfollowUp'
import changeFollower from '../IntentionDetail/components/changeFollower'
import {
  deletsale,
  getSaleListInfo,
  getUser,
  saveSale, shopPotentialVisit, upDateSale
} from "@/views/userOperations/IntentionDetail/components/saleChnge/api";
import { getSList } from "@/views/userOperations/OpportunityList/api";
import { changeFollowPeople } from "@/views/userOperations/OpportunityDetail/api";

export default {
  components: {
    addEditModal,
    fllowUprecord,
    makePlan,
    FillinfollowUp,
    changeFollower
  },
  data() {
    return {
      ProList: [],
      visibleDelect: false,
      visibleaddEdit: false,
      changeFollowervisible:false,
      typeText: "",
      userInfo: {},
      userLableList:[],
      pageLoadFlag: false,
      cherkItem: {},

      // 面包屑数据
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/OpportunityList",
          name: "/",
          title: "销售机会列表"
        },
        {
          path: "/",
          name: "/",
          title: "销售机会详情"
        }

      ],
      recoredList:[
        {
          time:'2022-1-2',
          message1:'zhangdanjkshfdkjdsfkhkkfkjdahf',
          type:'cherk',
          title:'微信跟进 (计划)',
          message:'wangwhwh',
          type1:'跟进'
        },
        {
          time:'2022-1-2',
          message1:'zhangdanjkshfdkjdsfkhkkfkjdahf',
          type:'cherk',
          title:'短信跟进 (计划)',
          message:'wangwhwh',
          type1:'跟进'
        },
        {
          time:'2022-1-2',
          message1:'zhangdanjkshfdkjdsfkhkkfkjdahf',
          type:'cherk',
          title:'跟进 (计划)',
          message:'wangwhwh',
          type1:'跟进'
        },
        {
          time:'2022-1-2',
          message1:'zhangdanjkshfdkjdsfkhkkfkjdsajdhakjdsh' +
            'wejkwjkwjah你急哦啊U盾临时决定加快速度发货时间快递费山东会计法核算会计东方红' ,
          type:'cherk',
          title:'已电话跟进',
          message:'wangwhwh',
          type1:'电话跟进'
        },
        {
          time:'2022-1-2',
          message1:'zhangdanjkshfdkjdsfkhkkfkjdahf',
          type:'cherk',
          title:'未当面跟进',
          message:'wangwhwh',
          type1:'未跟进'
        },
        {
          time:'2022-1-2',
          message1:'zhangdanjkshfdkjdsfkhkkfkjdahf',
          type:'cherk',
          title:'创建销售机会',
          message:'wangwhwh',
          type1:'创建'
        }
      ],
      userId:this.$route.query.userId,
      outId:'',
      efitInfo: {},
      status:undefined,
      pageElClickTime:'',
      //   定计划的弹窗
      palnvisible:false,
      //   去跟进的弹窗
      qugenjinvisible:false,
      infor:{
        type:'22套购',
        addressFull:'3333333',
        createdDateStr:'222333',
        creator:'3333213123',
        pList:[
          {
            'spartName':'空调',
            'product':'chanpin',
            'budget':'yushan333',
            'planBuyTimeStr':'dhijian',

          },
          {
            'proname':'3333',
            'product':'chanpin',
            'yusuan':'yushan333',
            'jihuashijian':'dhijian',
            spartName:'洗衣机'

          },
          {
            'proname':'koeengtiao',
            'product':'chddddanpin',
            'yusuan':'yushan333',
            'jihuashijian':'dhijian',

          }

        ]
      }

    };
  },
  props: {

  },
  watch: {

  },
  created(){
    this.userId =  this.$route.query.userId
  },

  mounted() {
    this.userId =  this.$route.query.userId
    this.$nextTick(()=>{
      this.getUserInfo();
      this.getSaleInfo();
    })
  },
  methods: {
    gotoUserDeatil(){
      let routeUrl = this.$router.resolve({
        path: "/IntentionDetail",
        query: {
          id: this.userId
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    openchangeFollower(){
      this.changeFollowervisible = true
    },
    changeFollowerOk(data){
      let dataA = {
        "id":this.userId,//userId
        "followPeople":data.followPeople,//跟进人ID
      }
      changeFollowPeople(dataA).then(res=>{
        if(res.data.code == 0) {
          this.$message.success('修改成功'+res.data.msg)
          this.changeFollowervisible = false
          this.getSaleInfo()
          this.getUserInfo()
        } else{
          this.$message.warning('修改失败'+res.data.msg)
          this.changeFollowervisible = false
        }

      }).catch(error=>{
        this.changeFollowervisible = false
      })
    },
    // 打开定计划弹窗
    openDJh(){
      this.palnvisible = true
    },
    // 制定计划
    cancelReasonHandOk(){
      this.getSaleInfo()
    },
    openFillinfollowVisible(){
      this.qugenjinvisible = true
    },
    // 填写跟进记录
    FillinfollowUpOk() {
      this.getSaleInfo()
    },

    // 获取销售信息xiangqing列表
    getSaleInfo() {
      this.pageLoadFlag = true;
      let data = {
        id: this.$route.query.id
      }
      getSList(data).then(res => {
        if (res.data.code == 0) {
          this.infor = res.data.list.length >0 ? res.data.list[0]:{}
          this.ProList = res.data.list;
          this.status = this.infor .status?Number(this.infor .status):undefined
          this.outId = String(this.infor.id)
          let data = {
            pageSize:1000,
            shopPotentialId:this.infor.id
          }
          // 查询跟进记录
          shopPotentialVisit(data).then(res=>{
            if(res.data.code == 0){
              this.infor.recoredList =  res.data.list
              this.$forceUpdate()
            } else {
              this.infor.recoredList =  []
              this.$forceUpdate()
            }
          })
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    },

    // 顶部用户信息
    getUserInfo() {
      this.pageLoadFlag = true;
      let data = {
        userId: this.$route.query.userId
      };
      getUser(data).then(res => {
        if(res.data.code == 0){
          this.userInfo = res.data.data;
          this.userLableList = this.userInfo.userLableList
          this.pageLoadFlag = false;
        }else {
          this.userLableList = []
          this.pageLoadFlag = false;
        }

      });
    },
    // 鼠标移入 展示查看详情
    mousemove(item) {
      item.display = true;
      this.$forceUpdate();
    },
    // 鼠标移出隐藏查看详情
    leave(item) {
      item.display = false;
      this.$forceUpdate();
    },

  }
};
