
import http from "@/utils/request";
// 列表查询
export function getSList(data) {
  return http({
    method: "post",
    url: "/fast/potential/shopPotentialProductList/page.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data
  });
}